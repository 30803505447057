import { createTheme, ThemeOptions } from '@mui/material/styles';
import _ from 'lodash';

/*
 * color-blindness.com/color-name-hue
 */
export const amaranth = '#e6364c';
export const blackSqueeze = '#f4f9fc';
export const tenn = '#d95a00';
export const denim = '#1262b3';
export const lochmara = '#0077cc';
export const solitude = '#e6ecf2';
export const lima = '#7ed321';
export const lima_dark = '#71C219';
export const christi = '#71c219';
export const white = '#fff';
export const green_button = '#55AA00';
export const green_button_hover = '#4D9900';

export const ui_color = lochmara;

const themeOptions: ThemeOptions = {
  typography: {
    // converted to rem, see https://mui.com/material-ui/customization/typography/#font-size
    fontSize: 14,
    // by default, MuiButton should not be all uppercase
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: denim,
    },
    secondary: {
      main: green_button,
      contrastText: white,
    },
    error: {
      main: amaranth,
    },
  },
  components: {
    //  https://mui.com/material-ui/migration/migration-v4/#variant-prop

    MuiDialogContent: {
      styleOverrides: {
        root: {
          marginTop: '.5rem',
          backgroundColor: 'rgb(247,247,247)',
          paddingTop: '.8rem!important',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          'input': {
            outline: 'none!important',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(1rem, .5rem) scale(1)',
        },
        shrink: {
          transform: 'translate(1rem, -8px) scale(0.75)',
          backgroundColor: 'white',
          zIndex: 1000,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '15px!important',
          padding: '9px 14px!important',
          border: '0!important',
          margin: '0!important',
        },
        root: {
          backgroundColor: 'white',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 1,
        },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          backgroundColor: 'inherit',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingLeft: '1rem',
        },
      },
    },

    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: green_button,
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: green_button_hover,
            color: 'white',
          },
          '&:active': {
            backgroundColor: green_button_hover,
            color: 'white',
          },
        },
        containedSecondary: {
          '&:hover': {
            color: 'white',
          },
        },
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.5)',
        },
        outlinedPrimary: {
          border: '1px solid rgba(0, 0, 0, 0.5)',
        },

        outlinedError: {
          '&:hover': {
            color: tenn,
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.1em',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          color: 'black',
          marginLeft: 5,
          MuiButton: {
            paddingBottom: '1px!important',
          },
        },
        indicator: {
          backgroundColor: ui_color,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          textTransform: 'none',
          letterSpacing: 'normal',
          color: 'black',
          '&.Mui-selected': {
            color: ui_color,
            fontWeight: 'bold',
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&svg': {
            backgroundColor: 'white',
          },
        },
      },
    },

    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: blackSqueeze,
          borderRadius: 2,
          height: 22,
          margin: 2,
        },
        deleteIcon: {
          height: 14,
          marginRight: 0,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
};

export const errorTheme = createTheme({
  ...themeOptions,
  palette: {
    ...themeOptions.palette,
    primary: {
      main: amaranth,
    },
    secondary: themeOptions.palette.error,
  },

  components: {
    ...themeOptions.components,
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: amaranth,
          fontWeight: 'bold',
          '&:hover': {
            color: 'white',
          },
          '&:active': {
            color: 'white',
          },
        },
        containedSecondary: {
          backgroundColor: 'rgb(128, 128, 255)', //  $lima:  // #7ED321
        },
      },
    },
  },
});

const condensedThemeSrc: ThemeOptions = {
  typography: {
    fontSize: 13.7142857136,
  },

  spacing: 4,

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '1px 8px!important',
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 13.7142857136,
          border: '0.0714285714rem solid rgb(230, 230, 230)',
          background: 'rgb(255, 255, 255)',
          color: 'rgb(84, 93, 102)',
          borderRadius: '0.1428571429rem',
          padding: '4px 8px!important',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 13.7142857136,
          padding: '4px 8px!important',
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          paddingTop: 4,
          paddingBottom: 0,
          '& .MuiAutocomplete-option': {
            paddingTop: 1,
            paddingBottom: 1,
          },
        },
        root: {
          '& input': {
            border: 'none!important',
          },
          '& .MuiInputBase-root': {
            outline: 'none!important',
            padding: '4px 8px!important',
            margin: '0!important',
            border: 'none!important',
          },
          '& .MuiAutocomplete-input': {
            outline: 'none!important',
            padding: '0!important',
            margin: '0!important',
          },
        },
      },
    },
  },
};

export const condensedTheme = createTheme(_.merge(_.cloneDeep(themeOptions), condensedThemeSrc));
const theme = createTheme(themeOptions);
export default theme;
